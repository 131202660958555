<template>
  <v-container fluid class="pricing-zones-page">
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <div :id="window.width < 600 ? 'pricing-zones-page-step-0' : ''"></div>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.pricingZonesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="pricingZonesHeaders"
            :disable-sort="tourActive"
            :class="tourActive ? 'no-pointers' : ''"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="pricingZones"
            :search="searchPricingZoneString"
            class="elevation-1 cursor-pointer"
            @click:row="editPricingZoneItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.pricingZones') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="pricing-zones-page-step-1"
                  v-model="searchPricingZoneString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'pricing-zones-page-step-0' : ''" class="first-step"></p>

                <v-spacer />

                <v-dialog v-model="pricingZoneDialog" max-width="900px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on" id="pricing-zones-page-step-2">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(pricingZoneFormTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingZoneEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(savePricingZone)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="8" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                  <v-text-field
                                    v-model="editedPricingZoneItem.name"
                                    autofocus
                                    clearable
                                    counter="200"
                                    :label="$t('pricingZonesPage.name')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="4" sm="12">
                                <validation-provider rules="required|max:50" v-slot="{ errors }" name="code">
                                  <v-text-field
                                    v-model="editedPricingZoneItem.code"
                                    clearable
                                    counter="50"
                                    :label="$t('pricingZonesPage.code')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required" v-slot="{ errors }" name="coordinates">
                                  <google-map-drawing
                                    :key="pricingZoneDialog"
                                    :editedPricingZoneItem="editedPricingZoneItem"
                                    @saveCoordinates="saveCoordinates" />
                                  <v-text-field
                                    hidden
                                    class="text-field-no-border"
                                    v-model="editedPricingZoneItem.value"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="pricingZoneDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedPricingZoneItem.name }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingZoneDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('pricingZonesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        :disabled="!enablePricingZoneSave"
                        class="primary"
                        text
                        type="submit"
                        @click="deletePricingZoneItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editPricingZoneItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="pricingZonesTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { pricingZonesHeaders } from '@/mixins/data-table-headers';
import { defaultPricingZone } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import GoogleMapDrawing from '@/components/zone-pricing/GoogleMapDrawing.vue';
import { getAllTourSteps, pricingZonesPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';

export default {
  name: 'PricingZonesPage',
  components: { ButtonSubmit, GoogleMapDrawing },
  data() {
    return {
      searchPricingZoneString: '',
      pricingZoneDialog: false,
      pricingZoneDialogDelete: false,
      pricingZones: [],
      pricingZoneFormTitle: 'pricingZonesPage.newItem',
      pricingZoneFormValid: true,
      editedPricingZoneItem: {},
      enablePricingZoneSave: true,
      loading: false,
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
    };
  },
  created() {
    this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
    this.loadAllPricingZones();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    pricingZonesHeaders() {
      return pricingZonesHeaders(i18n);
    },
  },
  methods: {
    async loadAllPricingZones() {
      await this.$store.dispatch('zonePricing/getAllPricingZones').then((res) => {
        this.pricingZones = res.data.map((e) => {
          return e;
        });
      });

      this.setStepsAndStartTour();
    },

    editPricingZoneItem(item) {
      this.editedPricingZoneItem = this.pricingZones.indexOf(item);
      this.editedPricingZoneItem = Object.assign({}, item);
      this.pricingZoneFormTitle = item.name;
      this.pricingZoneDialog = true;
    },

    deleteItem(item) {
      this.editedPricingZoneItem = this.pricingZones.indexOf(item);
      this.editedPricingZoneItem = Object.assign({}, item);
      this.pricingZoneDialogDelete = true;
    },

    async savePricingZone() {
      this.enablePricingZoneSave = false;
      this.loading = true;
      let path = 'savePricingZone';

      if (this.editedPricingZoneItem.id) {
        Object.assign(this.editedPricingZoneItem, { _method: 'POST' });
        path = 'updatePricingZone';
      }

      await this.$store
        .dispatch('zonePricing/' + path, this.editedPricingZoneItem)
        .then(() => {
          this.loadAllPricingZones();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closePricingZoneEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enablePricingZoneSave = true;
          this.loading = false;
          this.pricingZoneFormTitle = 'pricingZonesPage.newItem';
        });
    },

    async deletePricingZoneItemConfirm() {
      await this.$store
        .dispatch('zonePricing/deletePricingZone', this.editedPricingZoneItem)
        .then(() => {
          this.loadAllPricingZones();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closePricingZoneDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePricingZoneDelete();
        });
    },

    closePricingZoneEdit() {
      this.pricingZoneDialog = false;
      this.$nextTick(() => {
        this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
      });
      this.$refs.form.reset();
      this.pricingZoneFormTitle = 'pricingZonesPage.newItem';
    },

    closePricingZoneDelete() {
      this.pricingZoneDialogDelete = false;
      this.$nextTick(() => {
        this.editedPricingZoneItem = Object.assign({}, defaultPricingZone);
      });
    },

    saveCoordinates(coordinates) {
      this.editedPricingZoneItem = { ...this.editedPricingZoneItem, value: coordinates };
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '9')
            ?.map((item) => item.step_id);
          this.steps = pricingZonesPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['pricingZonesTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.pricingZoneDialog = false;
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '9',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '9',
        step_id: `#pricing-zones-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '9',
            step_id: `#pricing-zones-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    pricingZoneDialog(val) {
      val || this.closePricingZoneEdit();
    },
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.text-field-no-border .v-input__control .v-input__slot::before {
  border: 0 !important;
}

.pricing-zones-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
  }
}
</style>
